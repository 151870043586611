<template>
  <div class="main">
    <CreateAdminRestaurant ref="CreateAdminRestaurant" />
    <EditAdminRestaurant ref="EditAdminRestaurant" />
    <b-row>
      <b-col md="12" class="m-2">
        <b-button variant="primary" @click="showModal">
          Adauga un restaurant
        </b-button>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Oras</label>
          <b-form-select @input="getRestaurants" v-model="filters.city_id" :options="citiesOptions" size="sm" class="form-control"></b-form-select>
        </div>
      </b-col>
      <b-col md="12">
        <b-form-input @keyup="getRestaurants" placeholder="Cauta..." type="text" v-model="paging.search"></b-form-input>
      </b-col>
      <b-col md="12">
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th style="min-width: 120px;"></b-th>
              <b-th>#ID</b-th>
              <b-th>Nume</b-th>
              <b-th>Adresa</b-th>
              <b-th>Cod Postal</b-th>
              <b-th>Oras</b-th>
            </b-tr>
          </b-thead>
          <draggable
              v-model="restaurants"
              tag="tbody"
              handle=".handle"
              ghost-class="ghost"
              @end="changePositions"
          >
            <b-tr v-for="restaurant of restaurants" :key="restaurant.id">
              <b-td>
                <b-link class="handle mr-2" style="cursor: move">
                  <i class="mdi mdi-sort"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
                <b-link
                    @click="editRestaurant(restaurant.id)"
                >
                  <i class="mdi mdi-pencil"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
                <b-link
                    class="ml-2"
                    @click="impersonateUser(restaurant.owner_user_id)"
                >
                  <i class="mdi mdi-account"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
              </b-td>
              <b-td>{{restaurant.id}}</b-td>
              <b-td>{{restaurant.name}}</b-td>
              <b-td>{{restaurant.street}}</b-td>
              <b-td>{{restaurant.zip}}</b-td>
              <b-td>{{restaurant.city.name}}</b-td>
            </b-tr>
          </draggable>
        </b-table-simple>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="restaurants.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AdminRestaurants from '@/components/adminEndpoints/restaurants'
import AdminCities from '@/components/adminEndpoints/cities'
import AdminUsers from '@/components/adminEndpoints/users'
import CreateAdminRestaurant from '@/views/Admin/Restaurants/CreateRestaurantModal'
import EditAdminRestaurant from '@/views/Admin/Restaurants/EditRestaurantModal'
import {bus} from "../../../main";
import draggable from "vuedraggable";

export default {
  data() {
    return {
      error: false,
      restaurants: [],
      cities: [],
      paging: {
        page: 1,
        per_page: 100,
        total: 10,
        search: ''
      },
      filters: {
        city_id: ''
      }
    }
  },
  computed: {
    citiesOptions() {
      const returnData = []
      const tmpObj = {
        value: '',
        text: 'TOATE ORASELE',
      }
      returnData.push(tmpObj)
      this.cities.forEach(city => {
        const tmpObj = {
          value: city.id,
          text: city.name
        }
        returnData.push(tmpObj)
      })
      return returnData
    },
  },
  components: {
    CreateAdminRestaurant,
    EditAdminRestaurant,
    draggable
  },
  methods: {
    changePositions(data) {
      this.restaurants.forEach((restaurant, index) => {
        this.restaurants[index].order_index = index
        this.restaurants[index].city_id = this.restaurants[index].city.id
        AdminRestaurants.update(restaurant.id, restaurant).then(() => {

        })
      })
    },
    editRestaurant(id) {
      bus.$emit('showAdminRestaurantEditModal', id)
    },
    showModal() {
      this.$root.$refs.AdminCreateRestaurantModal.showModal = true;
    },
    getRestaurants() {
      AdminRestaurants.getAll(this.paging, this.filters).then(response => {
        this.restaurants = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    getCities() {
      const paging = {
        page: 1,
        per_page: 100,
        total: 10,
        search: ''
      }
      AdminCities.getAll(paging).then(response => {
        this.cities = response.data.data
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getRestaurants()
    },
    async impersonateUser(userId) {
      let loginResponse = await AdminUsers.impersonate(userId)
      if (loginResponse.success) {
        localStorage.setItem('token', loginResponse.data.token)
        localStorage.setItem('userInfo', JSON.stringify(loginResponse.data.user_data))
        bus.$emit('loggedIn', loginResponse.data.user_data)
        this.$nextTick(() => {
          if (loginResponse.data.user_data.role === 'restaurant-manager') {
            this.$router.push({ name: 'restaurant-manager.dashboard' })
          } else if (loginResponse.data.user_data.role === 'city-manager') {
            this.$router.push({name: 'city-manager.dashboard'})
          } else if (loginResponse.data.user_data.role === 'driver') {
            this.$router.push({ name: 'driver.dashboard' })
          } else if (loginResponse.data.user_data.role === 'customer') {
            this.$router.push({ name: 'customer.account' })
          }
        })
      }
    },
  },
  mounted() {
    bus.$on('restaurantCreated', () => {
      this.getRestaurants()
    })
    bus.$on('restaurantUpdated', () => {
      this.getRestaurants()
    })
    this.getRestaurants()
    this.getCities()
  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #1bc5bd;
}
</style>