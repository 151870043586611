import axios from '@/components/plugins/axios'
const url = process.env.VUE_APP_API_URL

class Orders {
  get (slug) {
    return axios.get(url + '/admin/orders/' + slug)
  }

  getAll (params, filters = null) {
    let options = '?page=' + params.page + '&per_page=' + params.per_page
    if (typeof (params.search) !== 'undefined') {
      options += '&search=' + params.search
    }
    if (filters !== null) {
      Object.keys(filters).forEach(key => {
        options += '&filters[' + key + ']=' + filters[key]
      })
    }
    return axios.get(url + '/admin/orders' + options)
  }

  store (data) {
    return axios.post(url + '/admin/orders', data)
  }

  report(filters) {
    return axios.post(url + '/admin/orders/report', filters)
  }
}
export default new Orders()
