<template>
  <div class="main">
    <b-modal
        id="order-info"
        hide-header
        hide-footer
        v-model="showOrderInfoModal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <div>
        <b-row>
          <b-col md="12" v-if="selectedOrder !== null">
            <div class="box_order_form">
              <div class="head text-center" :class="orderStatusesBackgroundColors[selectedOrder.status]">
                <h3>Comanda #{{ selectedOrder.id }} ({{ formatDateTime(selectedOrder.created_at) }})</h3>
                <h3>Status: {{ orderStatus(selectedOrder.status) }}</h3>
                <b-alert class="p-1 text-center mt-2" v-if="selectedOrder.preorder_date" show variant="danger"><strong>Precomanda la:</strong> {{selectedOrder.preorder_date}}</b-alert>
                <h3 v-if="selectedOrder.status === 1">Gata de livrare la: {{ addMinutesToDate(selectedOrder.created_at, selectedOrder.est_restaurant_time_min) }} </h3>
                <b-col md="12" v-if="selectedOrder.driver_id !== null && selectedOrder.status !== 4">
                  <div class="detail_page_head pt-4 clearfix text-center">
                    <h5>DATA ESTIMATA LA CARE AJUNGE LIVRATORUL:</h5>
                    <h5> {{ addMinutesToDate(selectedOrder.created_at, selectedOrder.est_driver_time_to_restaurant_min) }}</h5>
                  </div>
                </b-col>
                <h3 v-if="selectedOrder.status === 4">Livrata la: {{ formatDateTime(selectedOrder.updated_at) }}</h3>
              </div>
              <!-- /head -->
              <div class="main">
                <b-row>
                  <b-col md="12">
                    <h4>Produse comandate</h4>
                    <ProductsDetails
                      :products=selectedOrder.products 
                    />
                  </b-col>
                  <b-col md="12">
                    <b-row class="text-center">
                      <b-col cols="6">
                        <h5>Detalii livrare</h5>
                        <div class="detail_page_head pt-4 clearfix">
                          <h6>{{ selectedOrder.order_details.firstName }} {{ selectedOrder.order_details.lastName }}</h6>
                          <span>{{ selectedOrder.order_details.street }} {{ selectedOrder.order_details.address_extra }}, {{ selectedOrder.order_details.zip }} {{ selectedOrder.order_details.city }}</span>
                          <p>{{ selectedOrder.order_details.phone }}</p>
                        </div>
                      </b-col>
                      <b-col cols="6">
                        <h4>Detalii plata</h4>
                        <div class="detail_page_head pt-4 clearfix">
                          <h6 v-if="!selectedOrder.voucher">Total de plata: {{ toCurrency(selectedOrder.order_total + selectedOrder.delivery_tax) }}</h6>
                          <h6 v-if="selectedOrder.voucher">
                            Total de plata: {{ toCurrency(selectedOrder.order_total + selectedOrder.delivery_tax - calculateVoucherAmount(selectedOrder)) }}
                          </h6>
                          <span>Produse: {{ toCurrency(selectedOrder.order_total) }}</span>
                          <p class="mb-0">Livrare: {{ toCurrency(selectedOrder.delivery_tax) }}</p>
                          <p v-if="selectedOrder.voucher && selectedOrder.voucher.value_type === 'fixed'" class="text-danger">
                            Cupon reducere: -{{ toCurrency(selectedOrder.voucher.value) }}
                          </p>
                          <p v-if="selectedOrder.voucher && selectedOrder.voucher.value_type === 'percent'" class="text-danger">
                            Cupon reducere: -{{ toCurrency(calculateVoucherAmount(selectedOrder)) }} ({{ selectedOrder.voucher.value }} %)
                          </p>
                        </div>
                      </b-col>
                      <b-col cols="12" v-if="selectedOrder.order_details.customer_notes">
                        <h4>Mentiuni comanda</h4>
                        <div class="detail_page_head clearfix">
                          <p>{{ selectedOrder.order_details.customer_notes }} </p>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-button variant="secondary" @click="showOrderInfoModal = false" style="width: 100%;">Inchide</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-row>
      <b-col md="4">
        <div class="form-group">
          <label>Filtru Restaurant</label>
          <b-form-select @input="getOrders" v-model="filters.restaurant_id" :options="restaurantsOptions" size="sm" class="form-control"></b-form-select>
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Status</label>
          <b-form-select @input="getOrders" v-model="filters.status" :options="statusesOptions" size="sm" class="form-control"></b-form-select>
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Livrator</label>
          <b-form-select @input="getOrders" v-model="filters.driver_id" :options="driversOptions" size="sm" class="form-control"></b-form-select>
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>City Manageri</label>
          <b-form-select @input="getOrders" v-model="filters.city_manager_id" :options="cityManagersOptions" size="sm" class="form-control"></b-form-select>
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Data (de la)</label>
          <flat-pickr
              @input="getOrders"
              :config="dateConfig"
              v-model="filters.from"
              class="form-control"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Data (pana la)</label>
          <flat-pickr
              @input="getOrders"
              :config="dateConfig"
              v-model="filters.to"
              class="form-control"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Metoda de plata</label>
          <b-form-select @input="getOrders" v-model="filters.stripe_payment_id" :options="paymentModesOptions" size="sm" class="form-control"></b-form-select>
        </div>
      </b-col>
      <b-col md="12">
        Numar total de comenzi : <span><strong>{{paging.total}}</strong></span>
      </b-col>
      <b-col md="12" v-if="orders.length > 0">
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th></b-th>
              <b-th>#ID</b-th>
              <b-th>METODA PLATA</b-th>
              <b-th>DATA</b-th>
              <b-th>CLIENT</b-th>
              <b-th>RESTAURANT</b-th>
              <b-th>LIVRATOR</b-th>
              <b-th>VALOARE Comanda</b-th>
              <b-th>TAXA PE RESTAURANT</b-th>
              <b-th>TAXA LIVRARE</b-th>
              <b-th>TAXA CITY MANAGER</b-th>
              <b-th>STATUS</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="order of orders" :key="order.id">
              <b-td>
                <b-link
                    @click="showOrderInfo(order)"
                >
                  <i class="mdi mdi-information"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
              </b-td>
              <b-td>{{order.id}}</b-td>
              <b-td style="min-width: 140px;">
                <b-alert class="p-1 text-center" v-if="order.paid_with_card" show variant="success">CARD</b-alert>
                <b-alert class="p-1 text-center" v-else show variant="primary">CASH</b-alert>
              </b-td>
              <b-td style="min-width: 180px;">{{ formatDateTime(order.created_at) }}</b-td>
              <b-td style="min-width: 200px;">{{order.order_details.firstName}} {{order.order_details.lastName}}</b-td>
              <b-td style="min-width: 300px;">
                <b-row v-if="order.restaurant !== null">
                  <b-col md="12">
                    {{order.restaurant.name}}
                  </b-col>
                  <b-col md="12">
                    {{order.restaurant.city.name}}
                  </b-col>
                </b-row>
              </b-td>
              <b-td style="min-width: 150px">
                <span v-if="order.driver">
                  {{ order.driver.first_name }} {{ order.driver.last_name }}
                </span>
              </b-td>
              <b-td style="min-width: 200px;" v-if="order.voucher">{{ toCurrency(order.order_total + order.delivery_tax - calculateVoucherAmount(order)) }}</b-td>
              <b-td style="min-width: 200px;" v-else>{{ toCurrency(order.order_total + order.delivery_tax) }}</b-td>
              <b-td style="min-width: 200px;">{{ toCurrency(order.platform_fee_amount) }} ({{order.platform_fee}} %  + taxa fixa)</b-td>
              <b-td style="min-width: 200px;">{{ toCurrency(order.delivery_tax) }}
                <span v-if="order.order_details.extra_km_fixed_price && order.order_details.extra_km_price">
                   ({{ toCurrency(parseFloat(order.order_details.extra_km_fixed_price) + parseFloat(order.order_details.extra_km_price)) }} taxa extra)
                </span>
              </b-td>
              <b-td style="min-width: 200px;">{{ toCurrency(order.city_manager_platform_fee_amount) }} ({{order.city_manager_platform_fee}} %)</b-td>
              <b-td style="min-width: 200px;">{{ orderStatus(order.status) }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col md="12" v-if="orders.length === 0">
        <b-alert show variant="info">Nu exista comenzi</b-alert>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="orders.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
      <b-col md="4">
        <b-button variant="primary" @click="generateReport()">Generare raport PDF</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AdminOrders from '@/components/adminEndpoints/orders'
import AdminRestaurants from '@/components/adminEndpoints/restaurants'
import Users from '@/components/adminEndpoints/users'
import ProductsDetails from '@/components/shared/productsDetails'

import 'flatpickr/dist/flatpickr.min.css';
import flatPickr from 'vue-flatpickr-component'
const ro = require("flatpickr/dist/l10n/ro.js").default.ro;

export default {
  data() {
    return {
      showOnlyCardPayments: false,
      showOrderInfoModal: false,
      selectedOrder: null,
      error: false,
      counterOrders: 0,
      orders: [],
      paging: {
        page: 1,
        per_page: 10,
        total: 10
      },
      filters: {
        restaurant_id: '',
        status: '',
        driver_id: '',
        city_manager_id: '',
        from: '',
        to: '',
        stripe_payment_id: ''
      },
      restaurants: [],
      drivers: [],
      cityManagers: []
    }
  },
  methods: {
    showOrderInfo(order) {
      this.selectedOrder = order;
      this.showOrderInfoModal = true;
    },
    getAllDrivers() {
      const params = {
        page: 1,
        per_page: 100
      }
      const filters = {
        role: 'driver'
      }
      Users.getAll(params, filters).then(response => {
        this.drivers = response.data.data
      })
    },
    getAllCityManagers() {
      const params = {
        page: 1,
        per_page: 100
      }
      const filters = {
        role: 'city-manager'
      }
      Users.getAll(params, filters).then(response => {
        this.cityManagers = response.data.data
      })
    },
    getOrders() {
      AdminOrders.getAll(this.paging, this.filters).then(response => {
        this.orders = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getOrders()
    },
    getRestaurants() {
      const paging = {
        page: 1,
        per_page: 999
      }
      AdminRestaurants.getAll(paging).then(response => {
        this.restaurants = response.data.data
      })
    },
    generateReport() {
      AdminOrders.report(this.filters).then(response => {
        const uid = response.data.uid
        window.open(process.env.VUE_APP_API_URL + '/orders/reports/' + uid, "_blank")
      })
    }
  },
  components: {
    flatPickr,
    ProductsDetails
  },
  mounted() {
    this.getAllDrivers()
    this.getAllCityManagers()
    this.getOrders()
    this.getRestaurants()
  },
  computed: {
    restaurantsOptions() {
      const returnData = []
      const tmpObj = {
        value: '',
        text: 'TOATE RESTAURANTELE',
      }
      returnData.push(tmpObj)
      this.restaurants.forEach(restaurant => {
        const tmpObj = {
          value: restaurant.id,
          text: restaurant.name + ' ' + restaurant.city.name
        }
        returnData.push(tmpObj)
      })
      return returnData
    },
    statusesOptions() {
      const returnData = []
      const tmpObj = {
        value: '',
        text: 'TOATE',
      }
      returnData.push(tmpObj)
      Object.keys(this.orderStatuses).forEach(key => {
        const tmpObj = {
          value: key,
          text: this.orderStatuses[key],
        }
        returnData.push(tmpObj)
      });
      return returnData
    },
    driversOptions() {
      const returnData = []
      const tmpObj = {
        value: '',
        text: 'TOTI LIVRATORII',
      }
      returnData.push(tmpObj)
      this.drivers.forEach(user => {
        const tmpObj = {
          value: user.id,
          text: user.email + ' - ' + user.first_name + ' ' + user.last_name
        }
        returnData.push(tmpObj)
      })
      return returnData
    },
    paymentModesOptions() {
      return [
        {
          value: '',
          text: 'TOATE',
        },
        {
          value: 'cash',
          text: 'NUMERAR',
        },
        {
          value: 'card',
          text: 'CARD',
        }
      ]
    },
    cityManagersOptions() {
      const returnData = []
      const tmpObj = {
        value: '',
        text: 'TOTI MANAGERII',
      }
      returnData.push(tmpObj)
      this.cityManagers.forEach(user => {
        const tmpObj = {
          value: user.id,
          text: user.email + ' - ' + user.first_name + ' ' + user.last_name
        }
        returnData.push(tmpObj)
      })
      return returnData
    },
    dateConfig() {
      return {
        enableTime: false,
        dateFormat: "Y-m-d",
        altFormat: "d.m.Y",
        altInput: true,
        allowInput: true,
        locale: ro,
      }
    },
  },
}
</script>