<template>
  <div>
    <b-modal
        id="create-voucher"
        hide-header
        hide-footer
        v-model="showModal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <div>
        <b-row>
          <b-col md="12" class="text-center p-2">
            <h3>Actualizare voucher</h3>
          </b-col>
          <b-col md="12">
            <div class="form-group">
              <label>COD</label>
              <b-input type="text" v-model="voucher.code" placeholder="(ex: CORESSY10)"></b-input>
            </div>
            <div class="form-group">
              <label>Descriere</label>
              <b-input type="text" v-model="voucher.description" placeholder="Descriere"></b-input>
            </div>
            <div class="form-group">
              <label>Nr. maxim de utilizari (global)</label>
              <b-input type="number" v-model="voucher.max_use_times" placeholder=""></b-input>
            </div>
            <div class="form-group">
              <label>Valoare comanda minima</label>
              <b-input type="number" v-model="voucher.min_order_value" placeholder=""></b-input>
            </div>
            <div class="form-group">
              <label>Valoare voucher</label>
              <b-input type="number" v-model="voucher.value" placeholder=""></b-input>
            </div>
            <div class="form-group">
              <label>Tip Valoare</label>
              <b-form-select v-model="voucher.value_type" :options="voucherValueTypes" size="sm" class="form-control"></b-form-select>
            </div>
            <div class="form-group">
              <label>Orasele unde se poate folosi</label>
              <v-select
                  v-model="voucher.available_on_cities_ids"
                  dir="ltr"
                  multiple
                  label="text"
                  :closeOnSelect="false"
                  :reduce="text => text.value"
                  :options="citiesOptions"
              >
              </v-select>
            </div>
            <div class="form-group">
              <label>Data expirarii</label>
              <flat-pickr
                  :config="dateConfig"
                  v-model="voucher.expire_date"
                  class="form-control"
              />
            </div>
            <div class="form-group">
              <label>Status</label>
              <b-form-select v-model="voucher.active" :options="statuses" size="sm" class="form-control"></b-form-select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-button variant="secondary" @click="cancel" style="width: 100%;">Anuleaza</b-button>
          </b-col>
          <b-col md="6" class="p-1">
            <b-button v-if="!isBusy" variant="primary" @click="save" style="width: 100%;">Salveaza</b-button>
            <b-button v-else variant="secondary" style="width: 100%;">Se salveaza...</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vouchers from '@/components/adminEndpoints/vouchers'
import Cities from '@/components/adminEndpoints/cities'
import { bus } from "../../../main";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';

import 'flatpickr/dist/flatpickr.min.css';
import flatPickr from 'vue-flatpickr-component'
const ro = require("flatpickr/dist/l10n/ro.js").default.ro;

export default {
  data() {
    return {
      isBusy: false,
      showModal: false,
      voucher: {
        code: 'CORESSY10',
        description: 'Reducere 10 RON',
        max_use_times: 100,
        available_on_cities_ids: [],
        min_order_value: 30,
        value: 10,
        value_type: 'fixed',
        expire_date: new Date(),
        active: true,
      },
      statuses: [
        { value: true, text: 'Activ' },
        { value: false, text: 'Inactiv' },
      ],
      voucherValueTypes: [
        { value: 'fixed', text: 'Valoare fixa (RON)' },
        { value: 'percent', text: '% din valoare comenzii' },
      ],
      cities: []
    }
  },
  components: {
    vSelect,
    flatPickr
  },
  created() {
    this.$root.$refs.EditVoucherModal = this;
  },
  methods: {
    cancel() {
      this.showModal = false
      this.voucher = {
        code: 'CORESSY10',
        description: 'Reducere 10 RON',
        max_use_times: 100,
        available_on_cities_ids: [],
        min_order_value: 30,
        value: 10,
        value_type: 'fixed',
        expire_date: new Date(),
        active: true,
      }
      this.isBusy = false;
    },
    save() {
      this.isBusy = true;
      Vouchers.update(this.voucher.id, this.voucher).then(response => {
        bus.$emit('voucherUpdated', response.data.data)
        this.cancel()
      }).catch(() => {
        this.isBusy = false
      })
    },
    getAllCities() {
      const params = {
        page: 1,
        per_page: 100
      }
      Cities.getAll(params).then(response => {
        this.cities = response.data.data
      })
    },
  },
  mounted() {
    this.getAllCities()
  },
  computed: {
    dateConfig() {
      return {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        altFormat: "d.m.Y H:i",
        altInput: true,
        allowInput: true,
        locale: ro,
      }
    },
    citiesOptions() {
      const returnData = []
      this.cities.forEach(city => {
        const tmpObj = {
          value: city.id,
          text: city.name
        }
        returnData.push(tmpObj)
      })
      return returnData
    }
  }
}
</script>