import axios from '@/components/plugins/axios'
const url = process.env.VUE_APP_API_URL

class Users {
  get (slug) {
    return axios.get(url + '/admin/users/' + slug)
  }

  getAll (params, filters = null) {
    let options = '?page=' + params.page + '&per_page=' + params.per_page
    if (typeof (params.search) !== 'undefined') {
      options += '&search=' + params.search
    }
    if (filters !== null) {
      Object.keys(filters).forEach(key => {
        options += '&filters[' + key + ']=' + filters[key]
      })
    }
    return axios.get(url + '/admin/users' + options)
  }

  store (data) {
    return axios.post(url + '/admin/users', data)
  }

  update (id, data) {
    return axios.put(url + '/admin/users/' + id, data)
  }

  async impersonate(userId) {
    let returnData = {
      success: false,
      data: ''
    }
    await axios.post(url + '/admin/users/impersonate/' + userId)
        .then(response => {
          returnData = response.data
        })
        .catch(error => {
          returnData = {
            success: false,
            data: error.response.data
          }
        })
    let impersonated = localStorage.getItem('impersonated');
    let impersonatedUsers = [];
    if (impersonated) {
      impersonatedUsers = JSON.parse(impersonated);
    }
    let impersonatedUserData = {
      user_data: JSON.parse(localStorage.getItem('userInfo')),
      token: localStorage.getItem('token')
    }
    impersonatedUsers.push(impersonatedUserData)
    localStorage.setItem('impersonated', JSON.stringify(impersonatedUsers))
    return returnData
  }
}
export default new Users()
