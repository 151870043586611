<template>
  <div>
    <b-modal
        id="create-city"
        hide-header
        hide-footer
        v-model="showModal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <div>
        <b-row>
          <b-col md="12" class="text-center p-2">
            <h3>Actualizare oras</h3>
          </b-col>
          <b-col md="12">
            <div class="form-group">
              <label>Nume</label>
              <b-input type="text" v-model="city.name" placeholder="Nume (obligatoriu)"></b-input>
            </div>
            <div class="form-group">
              <label>Manager Oras</label>
              <b-form-select v-model="city.city_manager_id" :options="cityManagersOptions" size="sm" class="form-control"></b-form-select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" class="p-1">
            <b-button variant="secondary" @click="cancel" style="width: 100%;">Anuleaza</b-button>
          </b-col>
          <b-col md="6" class="p-1">
            <b-button v-if="!isBusy" variant="primary" @click="save" style="width: 100%;">Salveaza</b-button>
            <b-button v-else variant="secondary" style="width: 100%;">Salveaza...</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Cities from '@/components/adminEndpoints/cities'
import Users from '@/components/adminEndpoints/users'
import { bus } from "../../../main";

export default {
  data() {
    return {
      isBusy: false,
      files: [],
      edit: false,
      showModal: false,
      city: {
        name: '',
        city_manager_id: null,
      },
      cityManagers: [],
    }
  },
  components: {

  },
  created() {
    this.$root.$refs.AdminEditCityModal = this;
  },
  methods: {
    cancel() {
      this.showModal = false
      this.city = {
        name: '',
        city_manager_id: ''
      }
      this.isBusy = false;
    },
    save() {
      this.isBusy = true;
      Cities.update(this.city.id, this.city).then(response => {
        bus.$emit('cityUpdated', response.data)
        this.cancel()
      })
    },
    getCity(id) {
      Cities.get(id).then(response => {
        this.city = response.data.data
      })
    },
    getAllCityManagers() {
      const params = {
        page: 1,
        per_page: 100
      }
      const filters = {
        role: 'city-manager'
      }
      Users.getAll(params, filters).then(response => {
        this.cityManagers = response.data.data
      })
    },
  },
  mounted() {
    this.getAllCityManagers()
    bus.$on("showAdminCityEditModal", id => {
      this.getCity(id);
      this.showModal = true;
    })
  },
  computed: {
    cityManagersOptions() {
      const returnData = []
      this.cityManagers.forEach(user => {
        const tmpObj = {
          value: user.id,
          text: user.email + ' - ' + user.first_name + ' ' + user.last_name
        }
        returnData.push(tmpObj)
      })
      return returnData
    }
  }
}
</script>
