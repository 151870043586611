import axios from '@/components/plugins/axios'
const url = process.env.VUE_APP_API_URL

class Cities {
  get (id) {
    return axios.get(url + '/admin/cities/' + id)
  }

  getAll (params) {
    let options = '?page=' + params.page + '&per_page=' + params.per_page
    if (typeof (params.search) !== 'undefined') {
      options += '&search=' + params.search
    }
    return axios.get(url + '/admin/cities' + options)
  }

  update (id, data) {
    return axios.put(url + '/admin/cities/' + id, data)
  }

  store (data) {
    return axios.post(url + '/admin/cities', data)
  }
}
export default new Cities()
