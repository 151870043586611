import axios from '@/components/plugins/axios'
const url = process.env.VUE_APP_API_URL

class Restaurants {
  get (id) {
    return axios.get(url + '/admin/restaurants/' + id)
  }

  getAll (params, filters = null) {
    let options = '?page=' + params.page + '&per_page=' + params.per_page
    if (typeof (params.search) !== 'undefined') {
      options += '&search=' + params.search
    }
    if (filters !== null) {
      Object.keys(filters).forEach(key => {
        options += '&filters[' + key + ']=' + filters[key]
      })
    }
    return axios.get(url + '/admin/restaurants' + options)
  }

  store (data) {
    return axios.post(url + '/admin/restaurants', data)
  }

  update (id, data) {
    return axios.put(url + '/admin/restaurants/' + id, data)
  }
}
export default new Restaurants()
