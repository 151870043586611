<template>
  <div>
    <b-modal
        id="create-restaurant"
        hide-header
        hide-footer
        v-model="showModal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <div>
        <b-row>
          <b-col md="12" class="text-center p-2">
            <h3>Adaugare restaurant</h3>
          </b-col>
          <b-col md="12">
            <div class="form-group">
              <label>Email</label>
              <b-input type="text" v-model="restaurant.email" placeholder="Email"></b-input>
            </div>
            <div class="form-group">
              <label>Nume restaurant</label>
              <b-input type="text" v-model="restaurant.name" placeholder="Nume (obligatoriu)"></b-input>
            </div>
            <div class="form-group">
              <label>Adresa restaurant</label>
              <b-input type="text" v-model="restaurant.street" placeholder="Strada nr, bloc, ap, etc (obligatoriu)"></b-input>
            </div>
            <div class="form-group">
              <label>Timp estimat per livrare</label>
              <b-input type="text" v-model="restaurant.estimated_time" placeholder="50 min"></b-input>
            </div>
            <div class="form-group">
              <label>Text promotional</label>
              <b-input type="text" v-model="restaurant.text_promo" placeholder="Livrare gratuita"></b-input>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Oras</label>
                  <b-form-select v-model="restaurant.city_id" :options="citiesOptions" size="sm" class="form-control"></b-form-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Cod Postal</label>
                  <b-input type="text" v-model="restaurant.zip" placeholder="Nu este obligatoriu"></b-input>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Telefon</label>
              <b-input type="text" maxlength="10" v-model="restaurant.telefon" placeholder="Telefon (obligatoriu)"></b-input>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Taxa de livrare</label>
                  <b-input type="text" v-model="restaurant.delivery_tax" placeholder="Taxa de livrare (obligatoriu)"></b-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Procent Comision</label>
                  <b-input type="text" v-model="restaurant.platform_fee" placeholder="Procent comision (obligatoriu)"></b-input>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Valoare Fixa Comision (RON)</label>
                  <b-input type="text" v-model="restaurant.platform_fee_fixed_value" placeholder="Valoare fixa comision (obligatoriu)"></b-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Comanda minima (RON)</label>
                  <b-input type="text" v-model="restaurant.min_order_amount_value" placeholder="Clientul trebuie sa comande de cel putin X RON"></b-input>
                </div>
              </div>
            </div>        
            <div class="form-group">
              <label>Manager Restaurant</label>
              <b-form-select v-model="restaurant.owner_user_id" :options="restaurantManagersOptions" size="sm" class="form-control"></b-form-select>
            </div>
            <div class="form-group">
              <label>Restaurante Aditionale</label>
              <v-select
                  v-model="restaurant.additional_owners"
                  dir="ltr"
                  multiple
                  label="text"
                  :closeOnSelect="false"
                  :reduce="text => text.value"
                  :options="restaurantManagersOptions"
              >
              </v-select>
            </div>
            <div class="form-group">
              <label>Livratori</label>
              <v-select
                  v-model="restaurant.drivers_ids"
                  dir="ltr"
                  multiple
                  label="text"
                  :closeOnSelect="false"
                  :reduce="text => text.value"
                  :options="driversOptions"
              >
              </v-select>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Status Livrare</label>
                  <b-form-select v-model="restaurant.delivery_active" :options="statuses" size="sm" class="form-control"></b-form-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Plati cash</label>
                  <b-form-select v-model="restaurant.accept_cash_payment" :options="statuses" size="sm" class="form-control"></b-form-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Plati cu cardul</label>
                  <b-form-select v-model="restaurant.accept_online_payment" :options="statuses" size="sm" class="form-control"></b-form-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Restaurant Activ</label>
                  <b-form-select v-model="restaurant.is_active" :options="statuses" size="sm" class="form-control"></b-form-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Status vizibilitate pe site</label>
                  <b-form-select v-model="restaurant.is_public" :options="statuses" size="sm" class="form-control"></b-form-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Generare factura taxa de livrare</label>
                  <b-form-select v-model="restaurant.delivery_tax_invoice_active" :options="statuses" size="sm" class="form-control"></b-form-select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Imagine principala</label>
              <ImageUploadCropper
                  :aspectRatio="16/9"
                  :defaultImage="null"
                  @cropMode="edit = $event"
                  @fileUpdated="files = $event"
                  @fileRemoved="fileRemoved()"
                  :uploadButtonText="$t('media.uploadImage')"
                  :cropButtonText="$t('media.cropImage')"
                  :removeButtonText="$t('media.removeImage')"
              >
              </ImageUploadCropper>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-button variant="secondary" @click="cancel" style="width: 100%;">Anuleaza</b-button>
          </b-col>
          <b-col md="6" class="p-1">
            <b-button v-if="!isBusy" variant="primary" @click="save" style="width: 100%;">Salveaza</b-button>
            <b-button v-else variant="secondary" style="width: 100%;">Se salveaza...</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Restaurants from '@/components/adminEndpoints/restaurants'
import Cities from '@/components/adminEndpoints/cities'
import Users from '@/components/adminEndpoints/users'
import { bus } from "../../../main";
import ImageUploadCropper from "../../System/Media/ImageUploadCropper";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';

export default {
  data() {
    return {
      isBusy: false,
      files: [],
      edit: false,
      showModal: false,
      restaurant: {
        name: '',
        street: '',
        zip: '',
        city_id: '',
        telefon: '',
        email: '',
        is_active: true,
        is_public: true,
        platform_fee: 0,
        platform_fee_fixed_value: 0,
        delivery_tax: 0,
        owner_user_id: null,
        drivers_ids: [],
        min_order_amount_value: 30,
        delivery_active: true,
        accept_online_payment: false,
        accept_cash_payment: true,
        text_promo: '',
        estimated_time: '50 min',
        delivery_tax_invoice_active: true,
      },
      statuses: [
        { value: true, text: 'Activ' },
        { value: false, text: 'Inactiv' },
      ],
      restaurantManagers: [],
      drivers: [],
      cities: []
    }
  },
  components: {
    vSelect,
    ImageUploadCropper
  },
  created() {
    this.$root.$refs.AdminCreateRestaurantModal = this;
  },
  methods: {
    cancel() {
      this.showModal = false
      this.restaurant = {
        name: '',
        street: '',
        zip: '',
        city_id: '',
        telefon: '',
        email: '',
        is_active: true,
        is_public: true,
        platform_fee: 0,
        platform_fee_fixed_value: 0,
        delivery_tax: 0,
        owner_user_id: null,
        additional_owners: [],
        city_manager_user_id: null,
        drivers_ids: [],
        min_order_amount_value: 30,
        delivery_active: true,
        accept_online_payment: false,
        accept_cash_payment: true,
        text_promo: '',
        estimated_time: '50 min',
        delivery_tax_invoice_active: true,
      }
      this.files = [];
      this.isBusy = false;
    },
    fileRemoved() {
      this.files = []
    },
    save() {
      let storeData = new FormData();
      if (this.files.length) {
        storeData.append("files[]", this.files[0].file);
      }

      Object.entries(this.restaurant).forEach(([field, value]) => {
        storeData.append(field, value);
      });
      this.isBusy = true;
      Restaurants.store(storeData).then(response => {
        bus.$emit('restaurantCreated', response.data.data)
        this.cancel()
      }).catch(error => {
        if (error.response.data.type === 'cannot_get_geocodes') {
          this.$swal('Verifica adresa', 'Adresa nu poate fi gasita pentru a genera coordinatele gps', 'error');
        }
        console.log(error);
        this.isBusy = false
      })
    },
    getAllRestaurantsManagers() {
      const params = {
        page: 1,
        per_page: 100
      }
      const filters = {
        role: 'restaurant-manager'
      }
      Users.getAll(params, filters).then(response => {
        this.restaurantManagers = response.data.data
      })
    },
    getAllDrivers() {
      const params = {
        page: 1,
        per_page: 100
      }
      const filters = {
        role: 'driver'
      }
      Users.getAll(params, filters).then(response => {
        this.drivers = response.data.data
      })
    },
    getAllCities() {
      const params = {
        page: 1,
        per_page: 100
      }
      Cities.getAll(params).then(response => {
        this.cities = response.data.data
      })
    },
  },
  mounted() {
    this.getAllRestaurantsManagers()
    this.getAllDrivers()
    this.getAllCities()
  },
  computed: {
    restaurantManagersOptions() {
      const returnData = []
      this.restaurantManagers.forEach(user => {
        const tmpObj = {
          value: user.id,
          text: user.email + ' - ' + user.first_name + ' ' + user.last_name
        }
        returnData.push(tmpObj)
      })
      return returnData
    },
    driversOptions() {
      const returnData = []
      this.drivers.forEach(user => {
        const tmpObj = {
          value: user.id,
          text: user.email + ' - ' + user.first_name + ' ' + user.last_name
        }
        returnData.push(tmpObj)
      })
      return returnData
    },
    citiesOptions() {
      const returnData = []
      this.cities.forEach(city => {
        const tmpObj = {
          value: city.id,
          text: city.name
        }
        returnData.push(tmpObj)
      })
      return returnData
    }
  }
}
</script>

<style>

</style>
