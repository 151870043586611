<template>
  <main class="bg_gray" style="padding-top: 150px;">
    <b-container>
      <b-row>
        <b-col md="3">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>MENIU ADMINISTRARE</h3>
            </div>
            <!-- /head -->
            <div class="main">
              <div>
                <b-nav pills vertical class="text-center">
                  <b-nav-item :active="$route.name === 'admin.dashboard'" :to="{ name: 'admin.dashboard' }">Dashboard</b-nav-item>
                  <b-nav-item :active="$route.name === 'admin.cities'" :to="{ name: 'admin.cities' }">Orase</b-nav-item>
                  <b-nav-item :active="$route.name === 'admin.restaurants'" :to="{ name: 'admin.restaurants' }">Restaurante</b-nav-item>
                  <b-nav-item :active="$route.name === 'admin.orders'" :to="{ name: 'admin.orders' }">Comenzi</b-nav-item>
                  <b-nav-item :active="$route.name === 'admin.users'" :to="{ name: 'admin.users' }">Utilizatori</b-nav-item>
                  <b-nav-item :active="$route.name === 'admin.vouchers'" :to="{ name: 'admin.vouchers' }">Vouchere</b-nav-item>
                </b-nav>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'admin.dashboard'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>DASHBOARD</h3>
            </div>
            <!-- /head -->
            <Statistics />
            <b-button class="m-2" variant="primary" @click="generateSitemap">GENERARE SITEMAP</b-button>
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'admin.cities'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>ORASE</h3>
            </div>
            <Cities />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'admin.restaurants'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>RESTAURANTE</h3>
            </div>
            <Restaurants />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'admin.orders'">
          <div class="box_order_form" ref="OrdersTable">
            <b-row>
              <div class="head text-center">
                <h3>COMENZI</h3>
              </div>
              <div style="position: absolute; left: 15px; top: 5px;">
                <b-link
                    @click="ordersFullScreen"
                >
                  <i class="mdi mdi-arrow-expand-all"
                     style="font-size: 24px; color: white;">
                  </i>
                </b-link>
              </div>
            </b-row>
            <OrdersTable />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'admin.users'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>UTILIZATORI</h3>
            </div>
            <Users />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'admin.vouchers'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>VOUCHERE</h3>
            </div>
            <Vouchers />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import Statistics from '@/views/Admin/Statistics'
import Cities from '@/views/Admin/Cities/Cities'
import Restaurants from '@/views/Admin/Restaurants/Restaurants'
import OrdersTable from '@/views/Admin/Orders/Table'
import Users from '@/views/Admin/Users/Users'
import Vouchers from '@/views/Admin/Vouchers/Vouchers'

import AdminRestaurants from '@/components/adminEndpoints/restaurants'

export default {
  data() {
    return {
      error: false,
      restaurants: [],
      paging: {
        page: 1,
        per_page: 1000,
        total: 1000,
        search: ''
      },
      sitemap: ""
    }
  },
  components: {
    Statistics,
    Cities,
    Restaurants,
    OrdersTable,
    Users,
    Vouchers
  },
  methods: {
    generateSitemap() {
      AdminRestaurants.getAll(this.paging).then(response => {
        this.restaurants = response.data.data
        this.paging.total = response.data.meta.total

        this.sitemap = '<?xml version="1.0" encoding="UTF-8"?>\n<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">';

        this.restaurants.forEach(restaurant => {
          this.sitemap += "\n   <url>\n" +
              "        <loc>https://coressy.com/restaurante/"+ restaurant.slug +"</loc>\n" +
              "        <priority>0.7</priority>\n" +
              "        <changefreq>weekly</changefreq>\n" +
              "    </url>"
        })
        this.sitemap += "\n</urlset>"

        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.sitemap));
        element.setAttribute('download', 'sitemap.xml');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

      })
    },
    ordersFullScreen() {
      if(!document.fullscreenElement) {
        this.$refs.OrdersTable.requestFullscreen()
      } else {
        document.exitFullscreen();
      }
    }
  }
}
</script>