<template>
  <div class="main">
    <CreateVoucher ref="CreateAdminVoucher" />
    <EditVoucher ref="EditAdminVoucher" />
    <b-row>
      <b-col md="12" class="m-2">
        <b-button variant="primary" @click="showModal">
          Adauga un voucher
        </b-button>
      </b-col>
      <b-col md="12">
        <b-form-input @keyup="getVouchers" placeholder="Cauta..." type="text" v-model="paging.search"></b-form-input>
      </b-col>
      <b-col md="12">
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th style="min-width: 25px;"></b-th>
              <b-th>#ID</b-th>
              <b-th>COD</b-th>
              <b-th style="min-width: 150px;">Descriere</b-th>
              <b-th style="min-width: 100px;">Valoare</b-th>
              <b-th style="min-width: 100px;">Nr de utilizari</b-th>
              <b-th style="min-width: 100px;">Nr maxim utilizari</b-th>
              <b-th style="min-width: 100px;">Rest disponibil</b-th>
              <b-th style="min-width: 150px;">Orase alocate</b-th>
              <b-th style="min-width: 210px;">Data Expirarii</b-th>
              <b-th style="min-width: 0px;">Status</b-th>
            </b-tr>
          </b-thead>
          <b-tr v-for="voucher of vouchers" :key="voucher.id">
            <b-td>
              <b-link
                  @click="editVoucher(voucher.id)"
              >
                <i class="mdi mdi-pencil"
                   style="font-size: 24px; color: #A1A7AF;">
                </i>
              </b-link>
            </b-td>
            <b-td>{{voucher.id}}</b-td>
            <b-td>{{voucher.code}}</b-td>
            <b-td>{{voucher.description}}</b-td>
            <b-td>{{voucher.value}}<span v-if="voucher.value_type === 'fixed'">RON</span> <span v-else>%</span></b-td>
            <b-td>{{voucher.used_times}}</b-td>
            <b-td>{{voucher.max_use_times}}</b-td>
            <b-td>{{voucher.max_use_times - voucher.used_times}}</b-td>
            <b-td>
              <span v-for="(city, index) in voucher.assigned_cities" :key="index">
                • {{ city.name }}<br>
              </span>
            </b-td>
            <b-td>{{voucher.expire_date}}</b-td>
            <b-td>
              <span class="bg-success text-white p-2" v-if="voucher.active">
                ACTIV
              </span>
              <span class="bg-danger text-white p-2" v-else>
                INACTIV
              </span>
            </b-td>
          </b-tr>
        </b-table-simple>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="vouchers.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vouchers from '@/components/adminEndpoints/vouchers'
import AdminCities from '@/components/adminEndpoints/cities'
import CreateVoucher from '@/views/Admin/Vouchers/CreateVoucherModal'
import EditVoucher from '@/views/Admin/Vouchers/EditVoucherModal'
import { bus } from "../../../main";

export default {
  data() {
    return {
      error: false,
      vouchers: [],
      cities: [],
      paging: {
        page: 1,
        per_page: 100,
        total: 10,
        search: ''
      }
    }
  },
  computed: {
    citiesOptions() {
      const returnData = []
      const tmpObj = {
        value: '',
        text: 'TOATE ORASELE',
      }
      returnData.push(tmpObj)
      this.cities.forEach(city => {
        const tmpObj = {
          value: city.id,
          text: city.name
        }
        returnData.push(tmpObj)
      })
      return returnData
    },
  },
  components: {
    CreateVoucher,
    EditVoucher
  },
  methods: {
    editVoucher(id) {
      Vouchers.get(id).then(response => {
        this.$root.$refs.EditVoucherModal.voucher = response.data.data;
        this.$root.$refs.EditVoucherModal.showModal = true;
      });
    },
    showModal() {
      this.$root.$refs.CreateVoucherModal.showModal = true;
    },
    getVouchers() {
      Vouchers.getAll(this.paging, this.filters).then(response => {
        this.vouchers = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    getCities() {
      const paging = {
        page: 1,
        per_page: 100,
        total: 10,
        search: ''
      }
      AdminCities.getAll(paging).then(response => {
        this.cities = response.data.data
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getVouchers()
    },
  },
  mounted() {
    bus.$on('voucherCreated', () => {
      this.getVouchers()
    })
    bus.$on('voucherUpdated', () => {
      this.getVouchers()
    })
    this.getVouchers()
    this.getCities()
  }
}
</script>
