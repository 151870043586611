<template>
  <div class="main">
    <!--
    <b-row>
      <b-col md="3" class="p-1">
        <b-card-body class="bg-dark text-center rounded">
          <h2 class="mb-25 font-weight-bolder text-white">50</h2>
          <span class="text-white">Orase</span>
        </b-card-body>
      </b-col>
      <b-col md="3" class="p-1">
        <b-card-body class="bg-dark text-center rounded">
          <h2 class="mb-25 font-weight-bolder text-white">50</h2>
          <span class="text-white">Restaurante</span>
        </b-card-body>
      </b-col>
      <b-col md="3" class="p-1">
        <b-card-body class="bg-dark text-center rounded">
          <h2 class="mb-25 font-weight-bolder text-white">3</h2>
          <span class="text-white">Comenzi</span>
        </b-card-body>
      </b-col>
      <b-col md="3" class="p-1">
        <b-card-body class="bg-dark text-center rounded">
          <h2 class="mb-25 font-weight-bolder text-white">15</h2>
          <span class="text-white">Clienti</span>
        </b-card-body>
      </b-col>
      <b-col md="3" class="p-1">
        <b-card-body class="bg-dark text-center rounded">
          <h2 class="mb-25 font-weight-bolder text-white">3</h2>
          <span class="text-white">City Manageri</span>
        </b-card-body>
      </b-col>
    </b-row>
    -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: 0,
      restaurants: 0,
      users: 0,
    }
  }
}
</script>