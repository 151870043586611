<template>
  <div class="main">
    <CreateAdminCity ref="CreateAdminCity" />
    <EditAdminCity ref="EditAdminCity" />
    <b-row>
      <b-col md="12" class="m-2">
        <b-button variant="primary" @click="showModal">
          Adauga un oras
        </b-button>
      </b-col>
      <b-col md="12">
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th></b-th>
              <b-th>#ID</b-th>
              <b-th>Nume</b-th>
              <b-th>Manager</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="city of cities" :key="city.id">
              <b-link
                  @click="editCity(city.id)"
              >
                <i class="mdi mdi-pencil"
                   style="font-size: 24px; color: #A1A7AF;">
                </i>
              </b-link>
              <b-td>{{city.id}}</b-td>
              <b-td>{{city.name}}</b-td>
              <b-td>
                <span v-if="city.city_manager">{{city.city_manager.first_name}} {{city.city_manager.last_name}} - {{city.city_manager.email}}</span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="cities.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AdminCities from '@/components/adminEndpoints/cities'
import CreateAdminCity from '@/views/Admin/Cities/CreateCityModal'
import EditAdminCity from '@/views/Admin/Cities/EditCityModal'
import { bus } from "../../../main";

export default {
  data() {
    return {
      error: false,
      cities: [],
      paging: {
        page: 1,
        per_page: 10,
        total: 10
      }
    }
  },
  components: {
    CreateAdminCity,
    EditAdminCity
  },
  methods: {
    editCity(id) {
      bus.$emit('showAdminCityEditModal', id)
    },
    showModal() {
      this.$root.$refs.AdminCreateCityModal.showModal = true;
    },
    getCities() {
      AdminCities.getAll(this.paging).then(response => {
        this.cities = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getCities()
    }
  },
  mounted() {
    bus.$on('cityCreated', () => {
      this.getCities()
    })
    bus.$on('cityUpdated', () => {
      this.getCities()
    })
    this.getCities()
  }
}
</script>